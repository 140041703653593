import React, { Fragment, useContext, forwardRef, useState, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import {
  Typography, Box, Grid, Button, styled,
  Paper, Tooltip, IconButton,
  Table, TableContainer, TableBody, TableHead, TableRow, TableCell,
  Card, CardHeader, CardActions, CardContent, Collapse, IconButtonProps,
} from '@mui/material';
import { TableVirtuoso, Components, Virtuoso, VirtuosoHandle } from 'react-virtuoso';

import ContextApp from '../../../contexts/ContextApp';
import useFetchUserParcelsPaginated from './hooks/useFetchUserParcelsPaginated';
import { DateTimeCom } from '../../utility/DateTimeHelpers';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const { authState } = useContext(ContextApp);
  const { userParcels, loadMore } = useFetchUserParcelsPaginated(authState._id);
  console.log('userParcels: ', userParcels);
  const virtuoso = useRef<VirtuosoHandle | null>(null);
  const TableComponents = {
    Scroller: forwardRef((props, ref) => <TableContainer component={Paper} {...props} ref={ref as React.RefObject<HTMLTableSectionElement>} />),
    Table: (props: any) => <Table {...props} style={{ borderCollapse: 'separate' }} />,
    TableHead: TableHead,
    TableRow: TableRow,
    TableBody: forwardRef((props, ref) => <TableBody {...props} ref={ref as React.RefObject<HTMLTableSectionElement>} />),
  };  
  // HTMLDivElement
  // <HTMLAttributes<HTMLDivElement>, HTMLDivElement>
  /**
   * 
   * Scroller?: React.ComponentType<ScrollerProps & {
        context?: Context;
      }>;
    
  */
  return (
    <>
      <Box sx={{ flexGrow: 1, padding: '10px' }}>
        <Grid container>
          <Grid item xs={8}>
            <Typography variant='h5' sx={{ marginBottom: '10px' }}>Dashboard</Typography>
          </Grid>
          <Grid item xs={4} sx={{ textAlign: 'right' }}>
            {/* <Link to='CreateParcel'> */}
            <Button
              variant='contained'
              onClick={() => {
                navigate('CreateParcel');
              }}
            >
              New Parcel
            </Button>
            {/* </Link> */}
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ flexGrow: 1, padding: '10px' }}>
        <Virtuoso
          ref={virtuoso}
          data={userParcels?.data || []}
          endReached={loadMore}
          totalCount={userParcels?.data?.length || 0}
          style={{ height: '78vh', width: '100%' }}
          // style={{ height: '100%' }}
          itemContent={(
            index,
            parcel
          ) => <ParcelRowCard
              parcel={parcel}
            />}
        />
        {/* <TableVirtuoso
          style={{ height: '80vh' }}
          data={userParcels?.data || []}
          components={TableComponents as Components}
          endReached={loadMore}
          totalCount={userParcels?.data?.length || 0}
          fixedHeaderContent={() => (
            <TableRow>
              <TableCell />
              <TableCell sx={{ background: 'white' }}>Date Created</TableCell>
              <TableCell sx={{ background: 'white' }}>Tracking Number</TableCell>
              <TableCell sx={{ background: 'white' }}>Warehouse</TableCell>
              <TableCell sx={{ background: 'white' }}>Remarks</TableCell>
              <TableCell />
            </TableRow>
          )}
          itemContent={(index, parcel) => (
            <ParcelRow
              parcel={parcel}
            />
          )}
        /> */}
      </Box>
    </>
  );
};

export default Dashboard;

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

interface ParcelRowInterface {
  parcel: any;
}

const ParcelRowCard: React.FC<ParcelRowInterface> = ({
  parcel,
}) => {
  const navigate = useNavigate();
  const [rowExpanded, setRowExpanded] = useState<boolean>(false);
  const handleExpandClick = () => setRowExpanded((prevState: boolean) => !prevState);
  return (
    <Card variant='outlined' sx={{ marginBottom: '10px' }}>
      <CardHeader
        action={
          <>
            {/* <IconButton aria-label="settings">
              <MoreVertIcon />
            </IconButton> */}
            <IconButton
            onClick={() => {
                navigate(`editparcel/${parcel._id}`);
              }}
            >
              <Tooltip title='Edit'>
                <EditIcon />
              </Tooltip>
            </IconButton>
            <ExpandMore
              expand={rowExpanded}
              onClick={handleExpandClick}
              aria-expanded={rowExpanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </>
        }
        title={(
          <Grid container sx={{  }}>
            <Grid item>{DateTimeCom(parcel.createInfo.dateTime)}</Grid>
            <Grid item>
              <Typography variant='body1' sx={{overflowWrap: 'break-word'}}>
                {parcel.trackingNumber}
              </Typography>
              <Typography variant='caption'>
                {parcel.courierCompany}
              </Typography>
            </Grid>
            <Grid item>{parcel.forwardingWarehouse}</Grid>
          </Grid>
        )}
        // subheader="September 14, 2016"
      />
      <Collapse in={rowExpanded} timeout="auto" unmountOnExit>
        <CardContent>
          {parcel.remarks}
        </CardContent>
      </Collapse>
    </Card>
  );
};

const ParcelRow: React.FC<ParcelRowInterface> = ({
  parcel,
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  return (
    <>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell sx={{ background: 'white' }}>
          {DateTimeCom(parcel.createInfo.dateTime)}
        </TableCell>
        <TableCell sx={{ background: 'white'  }}>
          <Typography variant='body1' sx={{overflowWrap: 'break-word'}}>
            {parcel.trackingNumber}
          </Typography>
          <Typography variant='caption'>
            {parcel.courierCompany}
          </Typography>
        </TableCell>
        <TableCell sx={{ background: 'white'  }}>
          {parcel.forwardingWarehouse}
        </TableCell>
        <TableCell sx={{ background: 'white'  }}>
          {parcel.remarks}
        </TableCell>
        <TableCell sx={{ textAlign: 'right' }}>
          <IconButton
            onClick={() => {
              navigate(`editparcel/${parcel._id}`);
            }}
          >
            <Tooltip title='Edit'>
              <EditIcon />
            </Tooltip>
          </IconButton>
        </TableCell>
        <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                History
              </Typography>
            </Box>
          </Collapse>
        </TableCell>
        </TableRow>
      {/* </TableRow> */}
    </>
  );
};
