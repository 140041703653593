import React, { forwardRef, useRef, useImperativeHandle } from 'react';
import {
  Paper, InputBase, Divider, Button,
  IconButton, Tooltip,
} from '@mui/material';
import { Search, Clear, FilterList } from '@mui/icons-material';

function escapeRegExp(searchTerm: string) {
  return searchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
};

const displayClearButton = (options: any) => {
  let display = false;
  Object.keys(options).forEach((key) => {
    if (!!options[key]) display = true;
  });
  return display;
};

interface PaddingInterface {
  paddingTop?: number;
  paddingRight?: number;
  paddingBottom?: number;
  paddingLeft?: number;
}

interface MarginInterface {
  marginTop?: number;
  marginRight?: number;
  marginBottom?: number;
  marginLeft?: number;
}

export interface FilterInterface {
  isOpen: boolean;
  options: any;
}

interface SearchBarInterface {
  onChange: any;
  defaultValue?: string;
  defaultPlaceholder?: string;
  customPadding?: boolean;
  theme?: 'light' | 'dark';
  padding?: PaddingInterface;
  margin?: MarginInterface;
  filter?: FilterInterface;
  setFilter?: React.Dispatch<React.SetStateAction<FilterInterface>>;
  setOnFocus?: React.Dispatch<React.SetStateAction<any>>;
  ref?: React.Ref<any>;
  name?: string;
  disabled?: boolean;
  displayClearTextButton?: boolean;
  setFieldValue?: React.Dispatch<React.SetStateAction<any>>;
}

const SearchBar: React.FC<SearchBarInterface> = forwardRef(({
  onChange,
  defaultValue = '',
  defaultPlaceholder = "Search",
  customPadding = false,
  theme = "dark",
  padding = {
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
  },
  margin = {
    marginTop: 0,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 0,
  },
  filter,
  setFilter,
  setOnFocus,
  name,
  disabled,
  displayClearTextButton,
  setFieldValue,
}, ref) => {
  const searchBarRef: any = useRef();

  useImperativeHandle(ref, () => ({
    // getSearchBar() {
    searchBarRef,
    // }
  }));

  return (
    <div style={{ width: '100%', marginBottom: '10px' }}>
      <Paper
        color='dark'
        component="form"
        sx={{
          padding: '0px 0px 0px 0px',
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          // backgroundColor: 'red',
        }}
      >
        <Search sx={{ margin: '10px', }} />
        <Divider
          sx={{
            height: 30,
            // margin: '10px',
          }}
          orientation="vertical"
        />
        <InputBase
          sx={{
            // margin: '10px',
            flex: 1,
          }}
          placeholder={defaultPlaceholder}
          inputProps={{ 'aria-label': `${defaultPlaceholder}` }}
          onChange={(e: any) => onChange(escapeRegExp(e.target.value))}
          onKeyPress={(e: any) => {
            if (e.key === 'Enter') e.preventDefault();
          }}
          onFocus={() => {if (setOnFocus) setOnFocus(true)}}
          defaultValue={defaultValue}
          inputRef={searchBarRef}
          name={name}
          disabled={disabled}
          // onBlur={() => {if (setOnFocus) setOnFocus(false)}}
        />
        {
          displayClearTextButton ? (
            <IconButton
              size='medium'
              onClick={() => {
                if (searchBarRef && searchBarRef.current) {
                  searchBarRef.current.value = '';
                  if (name) document.getElementsByName(name)[0].focus();
                }
                if (setFieldValue) {
                  setFieldValue('')
                }
              }}
            >
              <Tooltip title='Clear'>
                <Clear fontSize='small' />
              </Tooltip>
            </IconButton>
          ) : null
        }
        {
          filter && setFilter ? (
            <>
              <Button
                variant='contained'
                sx={{ height: '25px', display: displayClearButton(filter.options) ? '' : 'none' }}
                onClick={() => {
                  setFilter((prevState) => {
                    const newOptions = { ...prevState.options };
                    Object.keys(newOptions).forEach((key) => {
                      newOptions[key] = undefined;
                    });
                    return {
                      isOpen: false,
                      options: newOptions,
                    }
                  });
                }}
              >
                Clear Filter
              </Button>
              <IconButton
                size='medium'
                onClick={() => setFilter((prevState) => ({ ...prevState, isOpen: true }))}
              >
                <Tooltip title='Filter'>
                  <FilterList fontSize='small' />
                </Tooltip>
              </IconButton>
            </>
          ) : null
        }
      </Paper>
    </div>
  );
});

export default SearchBar;
