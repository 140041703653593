import { Paginated } from '@feathersjs/feathers';
import { paramsForServer } from 'feathers-hooks-common';

import FeathersApp, { Service } from '../Feathers';
import { Shipment } from '../server-interface';

export const shipmentFeathersService: any = FeathersApp.service(Service.Shipment);

export const findShipmentsPaginated = (
  skip: number,
  searchQuery: string,
): Promise<Paginated<Shipment>> => (
  shipmentFeathersService.find({
    query: {
      $skip: skip,
      $sort: {
        'createInfo.dateTime': -1,
      },
      $or: [
        { contractNumber: { $regex: searchQuery, $options: 'i' } },
        { billOfLadingNumber: { $regex: searchQuery, $options: 'i' } },
      ].filter((item) => item),
    },
  })
);

export const findShipments = (): Promise<Shipment[]> => (
  shipmentFeathersService.find(paramsForServer({
    paginate: false,
    query: {
      $sort: {
        'createInfo.dateTime': -1,
      },
    },
  }))
);

export const getShipment = (shipmentId: string): Promise<Shipment> => (
  shipmentFeathersService.get(shipmentId)
);

export const createShipment = (data: Omit<Shipment, '_id'>): Promise<Shipment> => (
  shipmentFeathersService.create(data)
);

export const patchShipment = (shipmentId: string, data: Omit<Shipment, '_id'>): Promise<Shipment> => (
  shipmentFeathersService.patch(shipmentId, data)
);
