import { useEffect, useState } from "react";

import { findClients } from '../../../feathers/services/User.service';
import { User } from '../../../feathers/server-interface';

const useFetchClients= (searchQuery: string,) => {
  const [clients, setClients] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();
  
  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      try {
        const fetchedClients = await findClients(searchQuery);
        setClients(fetchedClients); 
      } catch (error: any) {
        setError(error.message);
      }
      setIsLoading(false);
    }
    init();
  }, [searchQuery]);

  return {
    clients,
    isLoading,
    error,
  };
};

export default useFetchClients;
