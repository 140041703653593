import feathers from '@feathersjs/feathers';
import socketio from '@feathersjs/socketio-client';
import auth from '@feathersjs/authentication-client';
import io from 'socket.io-client';

const socket = io(process.env.REACT_APP_APPSERVER_URL || 'http://localhost:3030', { transports: ['websocket']});
const app = feathers();

app.configure(socketio(socket, { timeout: 20000 }));
app.configure(auth({
  // storage: window.localStorage as any
  storage: window.sessionStorage as any,
}));

export enum Service {
  AUTHENTICATION= 'authentication',
  User = 'users',
  Parcel = 'parcels',
  Upload = 'upload',
  Storage = 'storage',
  Workspace = 'workspace',
  WpSwitch = 'wpswitch',
  Shipment = 'shipment',
  Order = 'order',
};

// Increase timeout
// (app.service('magic') as any).timeout = 200000;

export default app;
