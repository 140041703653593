import { Paginated } from '@feathersjs/feathers';
import { paramsForServer } from 'feathers-hooks-common';

import FeathersApp, { Service } from '../Feathers';
import { User, UserAdmin } from '../server-interface';

export const userFeathersService: any = FeathersApp.service(Service.User);

export const createUser = (data: Partial<any>): Promise<User> => (
  userFeathersService.create(data)
);

export const findClients = (
  searchQuery: string,
): Promise<User[]> => (
  userFeathersService.find(paramsForServer({
    query: {
      acessableWs: false,
      // $skip: skip,
      $or: [
        { username: { $regex: searchQuery, $options: 'i' } },
      ],
    },
  }))
);

export const usersFindAcWs = (): Promise<UserAdmin[]> => (
  userFeathersService.find(paramsForServer({
    paginate: false,
    query: {
      acessableWs: true,
      $sort: {
        username: 1,
      },
    },
  }))
);
