import { Paginated } from '@feathersjs/feathers';
import { paramsForServer } from 'feathers-hooks-common';

import FeathersApp, { Service } from '../Feathers';
import { ParcelDetailed, ParcelArticleMeasurement } from '../server-interface';
import mongoose from 'mongoose';

export const parcelFeathersService: any = FeathersApp.service(Service.Parcel);

export const findClientParcels = (clientId: string): Promise<ParcelDetailed[]> => (
  parcelFeathersService.find(paramsForServer({
    paginate: false,
    query: {
      client: clientId,
      $sort: {
        'createInfo.dateTime': -1,
      },
    },
  }))
);

export const findParcelsByShipment = (shipmentId: string): Promise<ParcelDetailed[]> => (
  parcelFeathersService.find(paramsForServer({
    paginate: false,
    query: {
      shipment: shipmentId,
      $sort: {
        'createInfo.dateTime': -1,
      },
    },
  }))
);

export const findParcelsByOrder = (orderId: string): Promise<ParcelDetailed[]> => (
  parcelFeathersService.find(paramsForServer({
    paginate: false,
    query: {
      order: orderId,
      $sort: {
        'createInfo.dateTime': -1,
      },
    },
  }))
);

export const findParcelsByUserPaginated = (
  userId: string,
  skip: number,
): Promise<Paginated<ParcelDetailed>> => (
  parcelFeathersService.find({
    query: {
      userId,
      $skip: skip,
      // $sort: {
      //   'updateInfo.dateTime': -1,
      // },
    },
  })
);

export const findParcelsByWarehousePaginated = (
  warehouseId: string,
  skip: number,
  searchQuery: string,
): Promise<Paginated<ParcelDetailed>> => (
  parcelFeathersService.find({
    query: {
      // forwardingWarehouse: warehouseId,
      // destinationWarehouse: warehouseId,
      $skip: skip,
      $sort: {
        'createInfo.dateTime': -1,
      },
      $or: [
        { trackingNumber: { $regex: searchQuery, $options: 'i' } },
      ],
      $and: [
        { $or: [{ forwardingWarehouse: warehouseId }, { destinationWarehouse: warehouseId }] },
      ]
    },
  })
);

export const parcelRecievedAtForwardingWarehouse = (parcelId: string): Promise<ParcelDetailed> => (
  parcelFeathersService.patch(parcelId, { arrivedForwardingWarehouse: new Date() })
);

export const createParcel = (data: Partial<ParcelDetailed>): Promise<ParcelDetailed> => (
  parcelFeathersService.create(data)
);

export const addParcleArticle = (
  parcelId: string,
  data: Omit<ParcelArticleMeasurement, '_id'>,
): Promise<ParcelDetailed> => (
  parcelFeathersService.patch(parcelId, {
    $push: { articleMeasurements: data },
  })
);

// export const patchParcleArticle = (
//   parcelId: string,
//   data: ParcelArticleMeasurement,
// ): Promise<ParcelDetailed> => (
//   parcelFeathersService.patch(null, {
//     'measurement.$.weight': data.weight,
//     'measurement.$.length': data.length,
//     'measurement.$.width': data.width,
//     'measurement.$.height': data.height,
//   }, {
//     query: { _id: parcelId, 'measurement._id': data._id },
//   })
// );

export const patchParcleArticle = (
  parcelId: string,
  data: ParcelArticleMeasurement,
): Promise<ParcelDetailed> => (
  parcelFeathersService.patch(parcelId, {
    // $set: {
      // 'measurement.$['weight']': data.weight,
      // 'measurement.$['length']': data.length,
      // 'measurement.$['width']': data.width,
      "articleMeasurements.$[articleMeasurement].height": data.height,
    // },
  }, {
    arrayFilters: [{ 'articleMeasurement._id': new mongoose.Types.ObjectId(data._id) }],
    // query: { _id: parcelId, 'measurement._id': data._id },
  })
);
// productCategoryFeathersService.patch(null, { 'subCategory.$.name': data.name }, { query: { _id: categoryId, 'subCategory._id': data.subCategoryId }})
export const patchParcel= (productId: string, data: Partial<ParcelDetailed>): Promise<ParcelDetailed> => (
  parcelFeathersService.patch(productId, data)
);

export const getParcel = (productId: string): Promise<ParcelDetailed> => (
  parcelFeathersService.get(productId)
);

// export const removeProduct = (productId: string): Promise<Product> => (
//   productFeathersService.remove(productId)
// );
