import React, { useEffect, useState } from 'react';
import { Grid, Switch, } from '@mui/material';

import { IsMobileView } from '../helpers/hooks/useMediaQuery';

interface LeftRightPanelInterface {
  leftPanelComponent: JSX.Element;
  rightPanelComponent: JSX.Element | null;
  openList?: boolean;
}

const LeftRightPanel: React.FC<LeftRightPanelInterface> = ({
  leftPanelComponent,
  rightPanelComponent,
  openList = true,
}) => {
  const [showLeftPanel, setShowLeftPanel] = useState<boolean>(openList);
  const mobileView = IsMobileView();
  // console.log('showLeftPanel: ', showLeftPanel);
  // console.log('mobileView: ', mobileView);
  useEffect(() => {
    if (!mobileView && !showLeftPanel) setShowLeftPanel(true);
  }, [mobileView, showLeftPanel]);

  const leftPanelStyle = mobileView ? {
    height: showLeftPanel ?  'calc(100vh - 20px - 40vh)' : '44px',
    padding: '10px',
    // backgroundColor: theme.customPalette.extended.grey[90],
  } : {
    height: showLeftPanel ? '44px' : 'calc(100vh - 64px)',
    padding: '10px',
  };

  const rightPanelStyle = mobileView ? {
    height: showLeftPanel ? 'calc(100vh - 64px - 44px)' : 'calc(100vh - calc(100vh - 128px - 60vh) - 26vh)',
    // overflowX: 'hidden',
    paddingBottom: '30vh',
    // scrollbar
  } : {
    height: showLeftPanel ? 'calc(100vh - 64px - 44px)' : 'calc(100vh - 48px)',
    // paddingBottom: '30vh',
    paddingBottom: '20px',
  };

  const leftPanelComponentHeight = mobileView ? '100%' : 'calc(100vh - 64px - 60px)';
  // const leftPanelComponentHeight = '100%';

  return (
    <Grid container>
      <Grid item xl={4} md={4} xs={12}>
        <div style={leftPanelStyle}>
          {
            mobileView ? (
              <Grid
                container
                sx={{
                  width: '100%',
                  padding: '10px',
                  // backgroundColor: 'dark',
                }}
              >
                <Grid item xl={6} md={6} xs={6}>
                  Show list
                </Grid>
                <Grid item xl={6} md={6} xs={6} style={{textAlign: 'right'}}>
                  <Switch
                    size='small'
                    checked={showLeftPanel}
                    onChange={() => setShowLeftPanel((prevState) => !prevState)}
                  />
                </Grid>
              </Grid>
            ) : null
          }
          <div style={showLeftPanel ? { height: leftPanelComponentHeight } : { display: 'none' }}>
            {leftPanelComponent}
          </div>
        </div>
      </Grid>
      <Grid item xl={8} md={8} xs={12}>
        <div style={rightPanelStyle}>
          {rightPanelComponent}
        </div>
      </Grid>
    </Grid>
  );
};

export default LeftRightPanel;
