import { useLayoutEffect, useState } from 'react';

function useMediaQuery () {
  const [screenSize, setScreenSize] = useState([0,0]);

  useLayoutEffect(() => {
    function updateScreenSize () {
      setScreenSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateScreenSize);
    updateScreenSize();
    return () => window.removeEventListener("resize", updateScreenSize);
  }, []);

  return screenSize;
}

function IsMobileView () {
  const [width] = useMediaQuery();
  const isMobileView = width < 900 ? true : false;
  return isMobileView;
}

export {
  useMediaQuery,
  IsMobileView,
};
