import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Typography, Button, Grid } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import ContextApp from '../../../contexts/ContextApp';
import FormTextField from '../../utility/forms/FormTextField';
import UploadFileField, { toBase64, uploadsToBlob, toBlob, CustomFileType } from '../../utility/forms/UploadFileField';
import { createParcel, patchParcel, getParcel } from '../../feathers/services/Parcel.service';
import { createUpload, findParcelsByModule, removeUpload } from '../../feathers/services/Upload.service';
import { Upload, User } from '../../feathers/server-interface';

const DashboardParcelForm: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { authState } = useContext(ContextApp);
  const [selectedParcel, setSelectedParcel] = useState<any>();
  const [initialFiles, setInitialFiles] = useState<Upload[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<CustomFileType[]>([]);
  const [initialized, setInitialzed] = useState<boolean>(false);

  useEffect(() => {
    const findParcel = async () => {
      try {
        if (id) {
          const foundParcel = await getParcel(id);
          if (!foundParcel) navigate('/dashboard');
          if ((foundParcel.client as User)._id === authState._id) setSelectedParcel(foundParcel);
        }
      } catch (error) {
        navigate('/dashboard');
        console.log(error);
      }
    };
    const findInitialUploads = async () => {
      try {
        if (id) {
          const foundUploads = await findParcelsByModule('parcels', id);
          const blobUploads = foundUploads ? await uploadsToBlob(foundUploads) : [];
          setInitialFiles(foundUploads);
          setUploadedFiles(blobUploads);
        }
      } catch (error: any) {
        console.log(error.message);
      } finally {
        setInitialzed(true);
      }
    };
    findParcel();
    findInitialUploads();
  }, [id, authState._id, navigate]);

  const schema = Yup.object().shape({
    forwardingWarehouse: Yup.string().required('Forwarding warehouse is required'),
    courierCompany: Yup.string().required('Courier company is required'),
    trackingNumber: Yup.string().required('Tracking number is required'),
    remarks: Yup.string().test(
      "len",
      "Maximum 200 characters",
      (val) => {
        if (val === undefined) {
          return true;
        }
        // return !!(val.length =< 5);
        return val.length === 0 || (val.length <= 200);
      }
    ),
  });

  return (
    <Box sx={{ flexGrow: 1, padding: '10px' }}>
      <Typography variant='h5'>
        {id ? 'Edit' : 'Create New'} Parcel
      </Typography>
      <button
        type='button'
        onClick={() => {
          var csvURL = window.URL.createObjectURL(uploadedFiles[0]);
          var tempLink = document.createElement('a');
          tempLink.href = csvURL;
          tempLink.setAttribute('download', uploadedFiles[0].name);
          tempLink.click();
        }}
      >
        Download File Object
      </button>
      <Formik
        enableReinitialize
        validationSchema={schema}
        initialValues={{
          userId: authState._id,
          forwardingWarehouse: selectedParcel?.forwardingWarehouse || '',
          courierCompany: selectedParcel?.courierCompany || '',
          trackingNumber: selectedParcel?.trackingNumber || '',
          remarks: selectedParcel?.remarks || '',
        }}
        onSubmit={async (values, actions) => {
          actions.setSubmitting(false);
          try {
            if (id && selectedParcel) {
              const { userId, ...rest } = values;
              // Patch Parcel
              await patchParcel(id, rest);
              // Compare Files -> Remove Files, Create Files
              // Files to add
              const filesToAdd = uploadedFiles.filter((file) => !file._id);
              console.log('filesToAdd: ', filesToAdd);
              const filedatas = await Promise.all(filesToAdd.map(async (file) => ({
                module: 'parcels',
                belongTo: id,
                fileName: file.name,
                fileSize: (file.size || '').toString(),
                fileType: file.type,
                uri: await Promise.resolve(toBase64(file)) as string,
              }))) as Omit<Upload, '_id'>[];
              for await (const filedata of filedatas) {
                await createUpload(filedata);
              }
              // Files to remove
              const filesToRemove = [];
              const initialFilesIds = initialFiles.map((initialFile) => initialFile._id);
              const uploadedFilesIds = uploadedFiles.map((uploadedFile) => uploadedFile._id);
              for (let i = 0; i < initialFilesIds.length; i++) {
                const target = initialFilesIds[i];
                const stillExists = uploadedFilesIds.includes(target);
                if (!stillExists) filesToRemove.push(target);
              }
              for await (const fileToRemove of filesToRemove) {
                await removeUpload(fileToRemove);
              }
              console.log('fileToRemove: ', filesToRemove);
              // console.log('initialFilesIds: ', initialFilesIds);
              // console.log('uploadedFilesIds: ', uploadedFilesIds);
              // console.log('filesToRemove: ', filesToRemove);
            } else {
              // Create Parcel
              const createdParcel = await createParcel(values);
              const filedatas = await Promise.all(uploadedFiles.map(async (file) => ({
                module: 'parcels',
                belongTo: createdParcel._id,
                fileName: file.name,
                fileSize: (file.size || '').toString(),
                fileType: file.type,
                uri: await Promise.resolve(toBase64(file)) as string,
              }))) as Omit<Upload, '_id'>[];
              for await (const filedata of filedatas) {
                await createUpload(filedata);
              }
            }
            navigate('/dashboard');
          } catch (error) {
            console.log('error: ', error);
          }
          actions.setSubmitting(false);
        }}
      >
        {({
          isSubmitting,
          errors,
          submitForm,
          dirty,
          touched,
          handleBlur,
          handleChange,
          setFieldValue,
          setTouched,
          resetForm,
        }) => (
          <Form>
            <Field
              component={FormTextField}
              name='forwardingWarehouse'
              type='text'
              label='Forwarding Warehouse'
              required
            />
            <Field
              component={FormTextField}
              name='courierCompany'
              type='text'
              label='Courier Company'
              required
            />
            <Field
              component={FormTextField}
              name='trackingNumber'
              type='text'
              label='Tracking Number'
              required
            />
            <Field
              component={FormTextField}
              name='remarks'
              type='text'
              label='Remarks'
              multiline
              maxRows={3}
            />
            {/* <DropzoneArea
              onChange={(files) => {
                setUploadedFiles(files);
              }}
              fileObjects={[]}
              acceptedFiles={["image/jpeg", "image/png", "image/bmp", "application/pdf"]}
              showFileNamesInPreview={true}
              showFileNames={true}
              initialFiles={[
                // `data:image/png;base64,${sample}`
              ]}
              filesLimit={3}
              // maxFileSize={}
            /> */}
            {
              initialized ? (
                <UploadFileField
                  uploadedFiles={uploadedFiles}
                  setUploadedFiles={setUploadedFiles}
                />
              ) : null
            }
            <Button
              variant='contained'
              sx={{ width: '100%', marginBottom: '10px' }}
              type='submit'
              disabled={isSubmitting || !dirty || (Object.keys(errors).length > 0)}
            >
              Submit
            </Button>
            <Button
              variant='contained'
              sx={{ width: '100%' }}
              onClick={() => {
                navigate('/dashboard');
              }}
            >
              Back
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default DashboardParcelForm;
