import React, { useRef, useState } from 'react';
import { Warehouse, ArrowRightAlt } from '@mui/icons-material';
import { Card, CardContent, Grid, Typography, Stack } from '@mui/material';

import useFetchClients from './hooks/useFetchClients';
import { User, Workspace } from '../../feathers/server-interface';
import PageTitle from '../../layout/PageTitle';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import useDebounce from '../../helpers/hooks/useDebounce';
import SearchBar from '../../utility/SearchBar';

interface ClientLeftPanelInterface {
  selectedClient: User | undefined;
  setSelectedClient: React.Dispatch<React.SetStateAction<User | undefined>>;
}

const ClientLeftPanel: React.FC<ClientLeftPanelInterface> = ({
  selectedClient,
  setSelectedClient,
}) => {

  return (
    <>
      <PageTitle title='Clients' />
      <ClientList
        selectedClient={selectedClient}
        setSelectedClient={setSelectedClient}
      />
    </>
  );
};

export default ClientLeftPanel;

interface ClientListInterface {
  selectedClient: User | undefined;
  setSelectedClient: React.Dispatch<React.SetStateAction<User | undefined>>;
}

const ClientList: React.FC<ClientListInterface> = ({
  selectedClient,
  setSelectedClient,
}) => {
  const virtuoso = useRef<VirtuosoHandle | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const debouncedSearchQuery = useDebounce(searchQuery, 250);
  const searchBarRef: any = useRef();

  const { clients } = useFetchClients(debouncedSearchQuery);

  return (
    <>
      <SearchBar
        defaultPlaceholder='Search Client'
        defaultValue={debouncedSearchQuery}
        onChange={(value: string) => setSearchQuery(value)}
        // setOnFocus={setDisplayList}
        ref={searchBarRef}
      />
      <div style={{ width: '100%', height: 'calc(100% - 64px)' }}>
        <Virtuoso
          ref={virtuoso}
          data={clients as User[] || []}
          // endReached={loadMore}
          totalCount={(clients || []).length}
          itemContent={(
            index,
            client: User,
          ) => <ClientListItem
            client={client}
            selectedClient={selectedClient}
            setSelectedClient={setSelectedClient}
          />}
        />
      </div>
    </>
  );
};

interface ClientListItemInterface {
  client: User;
  selectedClient: User | undefined;
  setSelectedClient: React.Dispatch<React.SetStateAction<User | undefined>>;
}

const ClientListItem: React.FC<ClientListItemInterface> = ({
  client,
  selectedClient,
  setSelectedClient,
}) => {
  return (
    <Card
      // className={`${classes.root} ${selectedShelve?._id === shelve._id ? classes.selected : ''}`}
      sx={{
        // width: '100%',
        height: '100px',
        marginBottom: '10px',
        cursor: 'pointer',
        borderColor: client._id === selectedClient?._id ? '#1976d2' : '',
      }}
      variant='outlined'
      onClick={() => setSelectedClient(client)}
    >
      <CardContent>
        <Typography variant='h6'>{client.username}</Typography>
      </CardContent>
    </Card>
  );
};
